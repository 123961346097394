const getters = {
  // 全局配置
  config: (state) => state.app.config,
  // 请求凭证
  token: (state) => state.user.token,
  // 用户信息
  userInfo: (state) => state.user.userInfo,
};

export default getters;
