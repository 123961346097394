<template>
  <el-scrollbar class="height-full">
    <div class="p-16px">
      <layouts-perm />
    </div>
  </el-scrollbar>
</template>

<script>
import LayoutsPerm from "../perm/index.vue";

export default {
  components: {
    LayoutsPerm,
  },
};
</script>
