<template>
  <div class="permission">
    <template v-if="hasPermission">
      <keep-alive v-if="$route.meta.keepAlive">
        <router-view></router-view>
      </keep-alive>
      <router-view v-else></router-view>
    </template>
    <template v-else>
      <div class="no-permission">
        <img src="@/assets/no-permission.png" />
        <div class="muted">暂无权限</div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["permission"]),
    hasPermission() {
      // const {
      //   permission: { root, auth },
      // } = this;

      // console.log(root);
      // console.log(auth);

      // const { path, meta } = this.$route;

      // console.log(path);
      // console.log(meta);

      // if (root) {
      //   // 超级管理员
      //   return true;
      // }
      // return false;
      return true;
    },
  },
};
</script>

<style scoped lang="scss">
.permission {
  .no-permission {
    padding-right: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 50px - 50px);

    img {
      width: 152px;
      height: 152px;
    }

    .muted {
      color: #999;
    }
  }
}
</style>
