<template>
  <div class="aside" v-if="!hasAside">
    <el-scrollbar class="scrollbar" style="height: 100%">
      <el-menu :default-active="currentPath" router>
        <template v-for="item in aside">
          <div :key="item.path" v-if="!item.meta.hidden">
            <el-submenu
              v-if="item.children"
              :key="item.path"
              :index="item.path"
            >
              <template slot="title">
                <span class="iconfont" :class="[item.meta.icon]"></span>
                <span>{{ item.meta.title }}</span>
              </template>
              <template v-for="children in item.children">
                <el-menu-item
                  v-if="!children.meta.hidden"
                  :key="children.path"
                  :index="children.path"
                >
                  <template slot="title">
                    <span>{{ children.meta.title }}</span>
                  </template>
                </el-menu-item>
              </template>
            </el-submenu>
            <el-menu-item v-else :key="'_' + item.path" :index="item.path">
              <template slot="title">
                <span class="iconfont" :class="[item.meta.icon]"></span>
                <span>{{ item.meta.title }}</span>
              </template>
            </el-menu-item>
          </div>
        </template>
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import { asyncRoutes } from "@/router";

export default {
  computed: {
    currentPath() {
      return this.$route.meta?.prevPath || this.$route.path;
    },
    aside() {
      const { meta } = this.$route;
      const item = asyncRoutes.find((item) => item.path === meta?.parentPath);
      return item?.children;
    },
    hasAside() {
      return this.aside?.every((item) => item.meta?.hidden);
    },
  },
};
</script>

<style lang="scss">
.aside {
  width: 150px;
  height: 100%;
  overflow: hidden;

  .el-scrollbar {
    height: 100%;

    .el-menu {
      width: 150px;
      border-right: none;

      :deep(.el-submenu__title),
      .el-menu-item {
        font-size: 13px;
        height: 48px;
        line-height: 48px;
        padding: 0 10px !important;
        box-sizing: border-box;
        min-width: auto;

        &.is-active {
          background-color: #f5f5ff;
          margin: 8px;
          border-radius: 8px;
        }
      }

      .el-submenu {
        .el-menu-item {
          padding-left: 34px !important;
          font-size: 13px;
        }

        &.is-active {
          // background-color: #000;
        }
      }

      .iconfont {
        margin-right: 10px;
        margin-left: 10px;
      }

      :deep(.el-submenu__icon-arrow) {
        margin-top: -4px;
      }
    }
  }
}
</style>
