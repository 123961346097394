<template>
  <div class="layouts">
    <div class="layouts-header">
      <layouts-header></layouts-header>
    </div>
    <div class="layouts-container">
      <div class="layouts-aside">
        <layouts-aside></layouts-aside>
      </div>
      <div class="layouts-main">
        <layouts-main></layouts-main>
      </div>
    </div>
  </div>
</template>

<script>
import LayoutsHeader from "./components/header/index.vue";
import LayoutsAside from "./components/aside/index.vue";
import LayoutsMain from "./components/main/index.vue";

export default {
  components: {
    LayoutsHeader,
    LayoutsAside,
    LayoutsMain,
  },
};
</script>

<style lang="scss">
.layouts {
  width: 100%;
  height: 100vh;
  box-sizing: content-box;
  overflow: hidden;
  .layouts-header {
    height: 50px;
  }
  .layouts-container {
    min-height: 0;
    height: calc(100vh - 50px);
    display: flex;
    .layouts-aside {
      width: 150px;
      height: calc(100vh - 50px);
    }
    .layouts-main {
      background-color: #f5f7f9;
      height: 100%;
      flex: 1;
    }
  }
}
</style>
