import Layout from "@/layouts/index.vue";

const routes = [
  {
    path: "/",
    meta: { title: "会员中心" },
    redirect: "/center",
    component: Layout,
    children: [
      {
        path: "/center",
        meta: {
          // hidden: false,
          title: "账户信息",
          parentPath: "/",
        },
        component: () => import("@/views/center/index.vue"),
      },
      {
        path: "/authorize",
        meta: {
          // hidden: false,
          title: "授权列表",
          parentPath: "/",
        },
        component: () => import("@/views/authorize/index.vue"),
      },
      {
        path: "/recharge-log",
        meta: {
          // hidden: false,
          title: "充值记录",
          parentPath: "/",
        },
        component: () => import("@/views/recharge/index.vue"),
      },
      {
        path: "/order-log",
        meta: {
          // hidden: false,
          title: "购买记录",
          parentPath: "/",
        },
        component: () => import("@/views/order/index.vue"),
      },
      // {
      //   path: "/setting",
      //   meta: {
      //     // hidden: false,
      //     title: "系统设置",
      //     parentPath: "/",
      //   },
      //   component: () => import("@/views/setting/index.vue"),
      // },
      {
        path: "/api",
        meta: {
          // hidden: false,
          title: "接口文档",
          parentPath: "/",
        },
        component: () => import("@/views/api/index.vue"),
      },
    ],
  },
];

export default routes;
