import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

import LayoutBlank from "@/layouts/components/blank.vue";
import async from "./async";

export const asyncRoutes = async;

export const constantRoutes = [
  {
    path: "/account",
    name: "account",
    component: LayoutBlank,
    redirect: "/account/login",
    children: [
      {
        path: "/account/login",
        name: "login",
        meta: {
          title: "登录",
          parentPath: "/account",
        },
        component: () => import("@/views/account/login.vue"),
      },
    ],
  },

  {
    path: "/403",
    component: () => import("@/views/error/403.vue"),
  },
  {
    path: "/500",
    component: () => import("@/views/error/500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    component: () => import("@/views/error/404.vue"),
  },
];

export const getAsyncRoutes = () => {
  return asyncRoutes;
};

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes: [...asyncRoutes, ...constantRoutes],
});

export default router;
