import axios from "axios";
import router from "@/router";
import store from "@/store";
import config from "@/config";

import NProgress from "nprogress";
import { Message } from "element-ui";

const service = axios.create({
  baseURL: `${process.env.VUE_APP_BASE_URL}/api`,
  timeout: 10 * 1000,
  headers: {
    "Content-Type": "application/json;",
    version: config.version,
  },
});

/** 请求拦截器 */
service.interceptors.request.use(
  (config) => {
    const token = store.getters.token;
    // 请求凭证
    if (token) {
      config.headers.token = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/** 响应拦截器 */
service.interceptors.response.use(
  (response) => {
    const { code, msg, data } = response.data;
    switch (code) {
      case 200:
        return data;
      case 400:
        Message({ type: "error", message: msg });
        return Promise.reject(msg);
      case 401:
        store.dispatch("actionLogout").then(() => {
          router.push("/account/login");
        });
        return Promise.reject(msg);
    }
  },
  (error) => {
    Message({ type: "error", message: error.message });
    return Promise.reject(error);
  }
);

const requset = {
  get(url, params) {
    return new Promise((resolve, reject) => {
      NProgress.start();
      service
        .get(url, { params: params })
        .then((resp) => {
          NProgress.done();
          resolve(resp);
        })
        .catch((error) => {
          NProgress.done();
          reject(error);
        });
    });
  },
  post(url, params) {
    return new Promise((resolve, reject) => {
      NProgress.start();
      service
        .post(url, params)
        .then((resp) => {
          NProgress.done();
          resolve(resp);
        })
        .catch((error) => {
          NProgress.done();
          reject(error);
        });
    });
  },
  upload(url, file) {
    return new Promise((resolve, reject) => {
      NProgress.start();
      service
        .post(url, file, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((resp) => {
          NProgress.done();
          resolve(resp);
        })
        .catch((error) => {
          NProgress.done();
          reject(error);
        });
    });
  },
};

export default requset;
