import { Session } from "@/utils/storage";
import key from "@/config/key";

import { apiLogin, apiLogout, apiGetMy } from "@/api/passport";

const state = {
  // 访问令牌
  token: Session.get(key.TOKEN) || "",
  // 账户信息
  userInfo: {},
};

const mutations = {
  SET_TOKEN(state, value) {
    state.token = value;
    Session.set(key.TOKEN, value);
  },
  SET_USERINFO(state, value) {
    state.userInfo = value;
  },
};

const actions = {
  // 账号登录
  actionLogin({ commit }, playload) {
    const { account, password } = playload;
    return new Promise((resolve, reject) => {
      apiLogin({
        account: account.trim(),
        password: password,
      })
        .then((resp) => {
          commit("SET_TOKEN", resp.token);
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 账号登出
  actionLogout({ commit }) {
    return new Promise((resolve, reject) => {
      apiLogout()
        .then((resp) => {
          commit("SET_TOKEN", "");
          commit("SET_USERINFO", {});
          Session.remove(key.TOKEN);
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 获取账户信息
  getMy({ commit }) {
    return new Promise((resolve, reject) => {
      apiGetMy()
        .then((resp) => {
          commit("SET_USERINFO", resp);
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  state,
  mutations,
  actions,
};
