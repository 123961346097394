import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import getters from "./getters";

const files = require.context("./modules", false, /\.js$/);

const modules = files.keys().reduce((modules, path) => {
  const name = path.replace(/^\.\/(.*)\.\w+$/, "$1");
  const value = files(path);
  modules[name] = value.default;
  return modules;
}, {});

export default new Vuex.Store({
  modules,
  getters,
});
